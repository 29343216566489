/*-----------------------------------------------------------------------------------

    Theme Name: Diving - Scuba Diving Responsive Website Template
    Description: Scuba Diving Responsive Website Template
    Author: Chitrakoot Web
    Version: 1.0

-----------------------------------------------------------------------------------*/

/* ===================================
	Default Header style
====================================== */

header{ transition: all 0.5s ease 0s; position: relative; z-index: 99999; }

.navbar-brand img{ max-height: 43px; transition-duration: 0.5s; }
.navbar-header-custom{ padding: 22px 0 20px 0; }
.navbar-default{ transition: all 0.5s ease 0s; -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); -ms-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); }
.navbar-default .container, .navbar-default .container-fluid{ position: relative; }
.menu_area{ position: relative; z-index: 2; }
.menu_area .navbar-brand{ transition: all 0.5s ease 0s; }

.navbar-nav li{ list-style: none; -webkit-transition: background .3s ease; -ms-transition: background .3s ease; transition: background .3s ease; }
.navbar-nav li.has-sub > a{ position: relative; }
.navbar-nav li.has-sub a:hover{ color: #fff; }
.navbar-nav li a{ display: block; color: #9d9d9d; font-size: 15px; font-weight: 500; }
.navbar-nav > li{ margin-left: 15px; float: left; }
.navbar-nav > li.has-sub{ padding-right: 13px; }
.navbar-nav > li > a{ position: relative; display: block; font-size: 14px; font-weight: 600; padding: 21px 0.5rem; color: #282b2d; letter-spacing: 0.8px; text-transform: uppercase; -webkit-transition-duration: 500ms; -o-transition-duration: 500ms; transition-duration: 500ms; }
.navbar-nav li.current > a, .navbar-nav li.active > a{ color: #007bff; }
.navbar-nav > li.has-sub > a:hover{ color: #000; }
.navbar > ul > li.has-sub > a:hover:after{ border-color: transparent #000 #000 transparent; }
.navbar > ul > li.has-sub > a:after{ position: absolute; top: 28px; right: -8px; content: ""; border-color: transparent #282b2d #282b2d transparent; border-style: solid; border-width: 0 1px 1px 0; display: block; height: 6px; transform: rotate(45deg); transition: border 0.3s ease 0s; width: 6px; }
.navbar > ul > li.current > a:after{ border-color: transparent #007bff #007bff transparent; }

.navbar-nav ul{ position: absolute; left: -9999px; }
.navbar ul ul li{ -webkit-transition: all .25s ease; -ms-transition: all .25s ease; transition: all .25s ease; margin-bottom: 12px; padding: 0 20px; }
.navbar ul ul li:last-child{ margin-bottom: 0; }
.navbar ul ul li.active > a{ color: #fff; }
.navbar-nav li > ul{ padding: 15px 0; margin: 0 0 0 10px; background: #282b2d; min-width: 225px; -webkit-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2); -ms-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2); -moz-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2); box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2); }
.navbar-nav li:hover > ul{ left: auto; }

.navbar-nav > li.has-sub > ul{ opacity: 0; margin-top: 10px; -webkit-transition: all .5s ease; -ms-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease; }
.navbar-nav > li.has-sub:hover > ul{ margin-top: 0; opacity: 1; }
.navbar-nav > li.has-sub:last-child:hover ul{ left: inherit; right: 0; }
.navbar li > ul ul{ margin-top: -39px; margin-left: 200px; min-width: 200px; }
.navbar li > ul ul ul{ margin-left: 175px; min-width: 130px; }
.navbar ul ul li:last-child > a, .navbar ul ul li.last-item > a{ border-bottom: 0; }
.navbar ul ul li.has-sub > a:after{ position: absolute; top: 12px; right: -3px; width: 8px; height: 2px; display: block; background: #9d9d9d; content: ""; }
.navbar ul ul li.has-sub > a:before{ position: absolute; top: 9px; right: 0; display: block; width: 2px; height: 8px; background: #9d9d9d; content: ''; -webkit-transition: all .25s ease; -ms-transition: all .25s ease; transition: all 0.25s ease; }
.navbar ul ul > li.has-sub:hover > a:before{ top: 17px; height: 0; }

/* atribute navigation */
.attr-nav{ float: right; margin-left: 11px; margin-right: -15px; }
.attr-nav .dropdown-toggle:after{ content: none; }
.attr-nav > ul{ padding: 0; margin: 0 0 -5px 0; list-style: none; display: inline-block; }
.attr-nav > ul > li{ float: left; display: block; }
.attr-nav > ul > li > a{ color: #282b2d; display: block; padding: 21px 12px; position: relative; font-size: 17px; }
.attr-nav > ul > li > a span.badge{ position: absolute; top: 50%; margin-top: -15px; right: 5px; font-size: 10px; padding: 2px 0 0 1px; width: 15px; height: 15px; color: #fff; border-radius: 15px; line-height: 12px; font-weight: 400; }
.attr-nav > ul > li.dropdown ul.dropdown-menu{ position: absolute; border: none; border-radius: 0; padding: 0; background: #282b2d; min-width: 225px; margin: 10px 0 0 10px; -webkit-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2); -ms-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2); -moz-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2); box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2); -webkit-transition: all .5s ease; -ms-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease; }

/* top search */
.top-search{ padding: 10px 0; display: none; }
.top-search input.form-control{ background-color: transparent; border: none; -moz-box-shadow: 0px 0px 0px; -webkit-box-shadow: 0px 0px 0px; -o-box-shadow: 0px 0px 0px; box-shadow: 0px 0px 0px; color: #fff; height: 40px; padding: 0 15px; }
.top-search input.form-control::placeholder{ color: #f1f1f1; opacity: 1; }
.top-search input.form-control::-ms-input-placeholder, .top-search input.form-control::-moz-input-placeholder{ color: #f1f1f1; }
.top-search .input-group-addon{ background-color: transparent; border: none; color: #fff; padding-left: 0; padding-right: 0; line-height: 1.7; font-size: 18px; }
.top-search .input-group-addon.close-search{ cursor: pointer; }

/* top search */
.top-search.white { background: #fff; }
.top-search.white input.form-control{ color: #282b2d; }
.top-search.white input.form-control::placeholder{ color: #394952; opacity: 1; }
.top-search.white input.form-control::-ms-input-placeholder { color: #394952; }
.top-search.white .input-group-addon{ color: #282b2d; }

/* Responsive CSS */
@media screen and (min-width: 992px){

	/* ===================================
		Default Header style
	====================================== */

	header.scrollHeader .navbar-default{ position: fixed; top: 0; z-index: 99; width: 100%; background: #fff; left: 0; transition: all 0.2s ease 0s;  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); -ms-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); }
	.wrapper-boxed .scrollHeader .navbar-default{ left: 0; margin: 0 auto; max-width: 1200px; right: 0; }
	.scrollHeader .navbar-brand img{ max-height: 35px; transition-duration: 0.5s; }
	.scrollHeader .navbar-header-custom{ padding: 10px 0; transition-duration: 0.5s; }
	.navbar-nav .megamenu{ margin: 0; padding: 30px 15px; right: 0; width: 100%; }
	.navbar .megamenu > li{ margin: 0; float: left; }
	.navbar .sub-title{ color: #fff; }
	.megamenu > li > ul{ left: 0; margin: 0; min-width: auto; position: static; width: 100%; }
	.navbar .megamenu li > ul{ margin: 0; min-width: auto; padding: 0; -webkit-box-shadow: none; -ms-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
	.navbar .megamenu ul li{ padding: 0; }
	.navbar .megamenu li.has-sub > a:after, .navbar .megamenu li.has-sub > a:before{ background: none; }

	/* atribute navigation */
	.scrollHeader .attr-nav > ul > li > a{ padding: 21px 12px; }
	.attr-nav > ul > li.dropdown ul.dropdown-menu{ visibility: hidden; top: 66px; border-radius: 0; opacity: 0; left: -225px; }
	.attr-nav > ul > li.dropdown:hover ul.dropdown-menu{ margin-top: 0; opacity: 1; visibility: visible; }
	.scrollHeader .attr-nav > ul > li.dropdown ul.dropdown-menu{ top: 67px; }

	/* header top position */
	.top-position { margin-top: -95px; }
	.top-position1 { margin-top: -96px; }

	/* ===================================
	    Header style01
	====================================== */

	.header-style1 { box-shadow: none; }
	.header-style1 #top-bar { background: none; border-bottom: 1px solid rgba(255, 255, 255, 0.2);  }
	.header-style1 .navbar-default{ -webkit-box-shadow: none; -ms-box-shadow: none; box-shadow: none; }

	.header-style1 .navbar-nav li.has-sub a:hover{ color: #fff; }
	.header-style1 .navbar-nav > li > a{ color: #fff; }
	.header-style1 .navbar-nav li.current > a{ color: #007bff; }
	.header-style1 .navbar-nav > li.has-sub > a:hover{ color: #fff; }
	.header-style1 .navbar > ul > li.has-sub > a:hover:after{ border-color: transparent #fff #fff transparent; }
	.header-style1 .navbar > ul > li.has-sub > a:after{  border-color: transparent #fff #fff transparent; }
	.header-style1 .navbar > ul > li.current > a:after{ border-color: transparent #007bff #007bff transparent; }

	.header-style1.scrollHeader .navbar-nav > li > a { color: #282b2d; }
	.header-style1.scrollHeader .navbar-nav > li.has-sub > a:hover { color: #000; }
	.header-style1.scrollHeader .navbar > ul > li.has-sub > a:after { border-color: transparent #282b2d #282b2d transparent; }
	.header-style1.scrollHeader .navbar-nav li.current > a{ color: #007bff; }
	.header-style1.scrollHeader .navbar-nav li.current > a:hover{ color: #007bff; }
	.header-style1.scrollHeader .navbar > ul > li.current > a:after{ border-color: transparent #007bff #007bff transparent; }

	/* atribute navigation */
	.header-style1 .attr-nav > ul > li > a{ color: #fff; }
	.header-style1.scrollHeader .attr-nav > ul > li > a{ color: #282b2d; }

	/* ===================================
	    Header style02
	====================================== */

	.header-style2 .navbar-default { -webkit-box-shadow: none; -ms-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
	
	
	/* ===================================
		Menu area light
	====================================== */
	.menu_area-light .navbar-nav li a { color: #394952; }
	.menu_area-light .navbar-nav > li > a { color: #fff; }
	.menu_area-light .navbar-nav li > ul { background: #fff; }
	.menu_area-light .navbar ul ul li.active > a { color: #282b2d; }
	.menu_area-light .navbar-nav li.has-sub a:hover{ color: #282b2d; }
	.menu_area-light .navbar-nav li.current > a{ color: #007bff; }
	.menu_area-light .navbar .sub-title { color: #282b2d; }
	.menu_area-light .navbar-nav > li.has-sub > a:hover{ color: #fff; }
	.menu_area-light .navbar > ul > li.has-sub > a:hover:after{ border-color: transparent #fff #fff transparent; }
	.menu_area-light .navbar > ul > li.has-sub > a:after{  border-color: transparent #fff #fff transparent; }
	.menu_area-light .navbar > ul > li.current > a:after{ border-color: transparent #007bff #007bff transparent; }

	.menu_area-light.scrollHeader .navbar-nav > li > a { color: #282b2d; }
	.menu_area-light.scrollHeader .navbar-nav > li.has-sub > a:hover { color: #000; }
	.menu_area-light.scrollHeader .navbar > ul > li.has-sub > a:after { border-color: transparent #282b2d #282b2d transparent; }
	.menu_area-light.scrollHeader .navbar-nav li.current > a{ color: #007bff; }
	.menu_area-light.scrollHeader .navbar-nav li.current > a:hover{ color: #007bff; }
	.menu_area-light.scrollHeader .navbar > ul > li.current > a:after{ border-color: transparent #007bff #007bff transparent; }	

}

@media screen and (max-width: 1199px){ 
 	.navbar-nav > li{ margin-left: 10px; }
}

@media screen and (max-width: 991px){ 

	/* ===================================
		Default Header style
	====================================== */	

	.navbar-header-custom{ padding: 4px 0 8px;  }
	.menu_area .navbar-brand{ margin-top: 6px;  }

	.navbar-nav li > ul{ -webkit-box-shadow: none; -ms-box-shadow: none; -moz-box-shadow: none; box-shadow: none;  }
	.navbar-nav > li.has-sub > ul{ opacity: 1; margin: 0; -webkit-box-shadow: none; -ms-box-shadow: none; -moz-box-shadow: none; box-shadow: none; -webkit-transition: all 0s ease; -ms-transition: all 0s ease; -moz-transition: all 0s ease; -o-transition: all 0s ease; transition: all 0s ease;  }
	.navbar li > ul ul{ margin: 0; min-width: auto;  }
	.navbar-nav > li.has-sub{ padding-right: 0;  }
	.navbar-nav > li > a{ padding: 14px 20px; color: #282b2d; font-size: 14px; font-weight: 500; }
	.navbar-nav > li > a:hover{ opacity: 1;  }
	.navbar-nav li.has-sub a:hover{ color: #282b2d;  }
	.navbar ul ul li.active > a{ color: #000;  }
	.navbar .sub-title { color: #282b2d; }

	.navbar-nav{ background: #fff; -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2); -ms-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2); -moz-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2); box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2); position: absolute; top: 71px; left: 0; width: 100%; transition-duration: 0; -ms-transition-duration: 0; -moz-transition-duration: 0; -webkit-transition-duration: 0; -o-transition-duration: 0; width: 100%; display: none; transition: none; -ms-transition: none; -moz-transition: none; -webkit-transition: none; -o-transition: none;  }
	.navbar-nav li.active > a{ opacity: 1;  }
	.navbar-nav ul{ width: 100%; display: none; transition: none; -ms-transition: none; -moz-transition: none; -webkit-transition: none; -o-transition: none;  }
	.navbar-nav li{ width: 100%; border-top: 1px solid #e6e6e6; position: relative; padding: 0; margin: 0;  }
	.navbar-nav ul li:hover{ background: #f5f5f5;  }
	.navbar-nav ul ul li{ height: auto;  }
	.navbar-nav li:hover > ul > li{ height: auto;  }

	.navbar ul li a{ width: 100%; border-bottom: 0;  }
	.navbar ul ul{ position: relative; left: 0; width: 100%; margin: 0; text-align: left; background: #f5f5f5; padding: 0;  }
	.navbar ul ul li{ margin-bottom: 0; padding: 0; border-color: #e6e6e6;  }
	.navbar ul ul li a{ width: 100%; border-bottom: 0; color: #575a7b; background: none; padding: 10px 30px;  }
	.navbar ul ul ul li a{ padding-left: 45px;  }
	.navbar > ul > li{ float: none;  }
	.navbar-nav ul ul{ position: relative; left: 0; width: 100%; margin: 0; text-align: left; background: #f5f5f5; padding: 0;  }

	.navbar > ul > li.has-sub > a:after, .navbar > ul > li.has-sub > a:before{ display: none;  }
	.navbar ul ul > li.has-sub > a:after, .navbar ul ul > li.has-sub > a:before{ display: none;  }
	.navbar-nav ul ul ul li.active a{ border-left: none;  }
	.navbar-nav > ul > li.has-sub > ul > li.active > a, .navbar-nav > ul ul > li.has-sub > ul > li.active > a{ border-top: none;  }
	.navbar li > ul ul ul{ margin-left: 0; background: #fff;  }
	.navbar ul ul ul ul li a{ padding-left: 55px;  }
	.navbar-nav .has-sub .has-sub .submenu-button{ height: 43px;  }
	.navbar-nav .has-sub .has-sub .submenu-button:after{ top: 19px;  }
	.navbar-nav .has-sub .has-sub .submenu-button:before{ top: 15px;  }

	.navbar-toggler{ background: #282b2d; width: 50px; height: 45px; padding: 0; border-radius: 0; position: absolute; right: 0; top: 13px; cursor: pointer; z-index: 12399994; border: none;  }
	.navbar-toggler:after{ position: absolute; top: 22px; right: 15px; display: block; height: 8px; width: 20px; border-top: 2px solid #fff; border-bottom: 2px solid #fff; content: "";  }
	.navbar-toggler:before{ -webkit-transition: all .3s ease; -ms-transition: all .3s ease; transition: all .3s ease; position: absolute; top: 16px; right: 15px; display: block; height: 2px; width: 20px; background: #fff; content: "";  }
	.navbar-toggler.menu-opened:after{ -webkit-transition: all .3s ease; -ms-transition: all .3s ease; transition: all .3s ease; top: 23px; border: 0; height: 2px; width: 19px; background: #fff; -webkit-transform: rotate(45deg); -moz-transform: rotate(45deg); -ms-transform: rotate(45deg); -o-transform: rotate(45deg); transform: rotate(45deg);  }
	.navbar-toggler.menu-opened:before{ top: 23px; background: #fff; width: 19px; -webkit-transform: rotate(-45deg); -moz-transform: rotate(-45deg); -ms-transform: rotate(-45deg); -o-transform: rotate(-45deg); transform: rotate(-45deg);  }
	.navbar-nav .submenu-button{ position: absolute; z-index: 99; right: 0; top: 0; display: block; height: 51px; width: 100%; cursor: pointer;  }
	.navbar-nav .submenu-button:after{ position: absolute; top: 24px; right: 16px; width: 10px; height: 2px; display: block; background: #282b2d; content: "";  }
	.navbar-nav .submenu-button:before{ position: absolute; top: 20px; right: 20px; display: block; width: 2px; height: 10px; background: #282b2d; content: "";  }
	.navbar-nav .has-sub.active > .submenu-button:before{ display: none;  }

	/* atribute navigation */
	.attr-nav{ float: left; margin-right: 70px; }
	.attr-nav > ul > li{ display: inline-block;  }
	.attr-nav > ul > li.dropdown ul.dropdown-menu{ left: -193px; top: 38px; display: none !important;  }
	.attr-nav > ul > li.dropdown.show ul.dropdown-menu{ display: block !important;  }
	.attr-nav > ul > li > a{ padding: 0;  }
	.attr-nav > ul > li > a span.badge { right: -8px; }

	/* header top position */
	.top-position { margin-top: -72px; }
	.top-position1 { margin-top: -73px; }

	/* ===================================
	    Header style01
	====================================== */
	.header-style1 { box-shadow: none; }
	.header-style1 #top-bar { display: none; background: none; border-bottom: 1px solid rgba(255, 255, 255, 0.35);  }
	.header-style1 .navbar-default{ -webkit-box-shadow: none; -ms-box-shadow: none; box-shadow: none; border-bottom:1px solid rgba(255, 255, 255, 0.35); }

	.header-style1 .navbar-toggler{ background: #fff;  }
	.header-style1 .navbar-toggler:after{ border-top: 2px solid #282b2d; border-bottom: 2px solid #282b2d; }
	.header-style1 .navbar-toggler:before{ background: #282b2d; }
	.header-style1 .navbar-toggler.menu-opened:after{  background: #282b2d; border-bottom: none;  }
	.header-style1 .navbar-toggler.menu-opened:before{ background: #282b2d; }

	.header-style1 .attr-nav > ul > li > a{ color: #fff; }

	/* ===================================
	    Header style02
	====================================== */

	.header-style2 .navbar-default { -webkit-box-shadow: none; -ms-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
}
