.blockquote {
  padding: 20px;
  margin: 30px 0;
  border-left: 3px solid #073d82;
  background: #f7f7f7;
  font-size: 18px;
  color: #073d82;
}

/*-----------------------------------------------------------------------------------

    Theme Name: Diving - Scuba Diving Responsive Website Template
    Description: Scuba Diving Responsive Website Template
    Author: Chitrakoot Web
    Version: 1.0

-----------------------------------------------------------------------------------*/
/* ----------------------------------

    01. Common styles
    02. Navigation
    03. Section heading
    04. Page title
    05. Portfolio gallery
    06. Service section
    07. Our Team
    08. Testimonial
    09. Pagination
    10. OwlCarousel
    11. Accordion style
    12. Tabs
    13. Pages
    14. Blog
    15. Others
    16. Footer
    
---------------------------------- */
/* ==================================
    Common styles
====================================== */
/* loader */
#preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  overflow: visible;
  background: #fff;
  display: table;
  text-align: center;
}

.loader {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  width: 200px;
  height: 200px;
}

.loader-icon {
  width: 80px;
  height: 80px;
  border: 5px solid #073d82;
  border-right-color: #eee;
  border-radius: 50%;
  position: relative;
  animation: loader-rotate 1s linear infinite;
  margin: 0 auto;
}

@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* theme color and basic options */
a:hover, a:active {
  color: #073d82;
  text-decoration: none;
}

.bg-primary {
  background: #073d82 !important;
}

.theme-overlay[data-overlay-dark]:before {
  background: #073d82;
}

.text-primary {
  color: #073d82 !important;
}

.bg-secondary {
  background: #f8ea49 !important;
}

.theme-secondary-overlay[data-overlay-dark]:before {
  background: #f8ea49;
}

.text-secondary {
  color: #f8ea49 !important;
}

a.text-white-hover:hover {
  color: #fff !important;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, .h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a {
  color: #1e2022;
}

h1 a:hover, h1 a:focus, h1 a:active {
  color: #073d82;
}

h2 a {
  color: #073d82;
}

h2 a:hover, h2 a:active, h2 a:focus {
  color: #073d82;
}

h3 a:hover, h3 a:active, h3 a:focus {
  color: #073d82;
}

h4 a:hover, h4 a:active, h4 a:focus {
  color: #073d82;
}

h5 a:hover, h5 a:active, h5 a:focus {
  color: #073d82;
}

h6 a:hover, h6 a:active, h6 a:focus {
  color: #073d82;
}

.h1 a:hover, .h1 a:active, .h1 a:focus {
  color: #073d82;
}

.h2 a:hover, .h2 a:active, .h2 a:focus {
  color: #073d82;
}

.h3 a:hover, .h3 a:active, .h3 a:focus {
  color: #073d82;
}

.h4 a:hover, .h4 a:active, .h4 a:focus {
  color: #073d82;
}

.h5 a:hover, .h5 a:active, .h5 a:focus {
  color: #073d82;
}

.h6 a:hover, .h6 a:active, .h6 a:focus {
  color: #073d82;
}

/* scroll to top */
.scroll-to-top {
  font-size: 20px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  border-radius: 50%;
  background: #000;
  border: 1px solid #2a2a2a;
  width: 35px;
  height: 35px;
  line-height: 30px;
  z-index: 9999;
  outline: 0;
  transition: all 0.3s ease;
}

.scroll-to-top i {
  color: #fff;
}

.scroll-to-top:hover {
  color: #1e2022;
  background: #fff;
}

.scroll-to-top:hover i {
  color: #1e2022;
}

.scroll-to-top:visited {
  color: #1e2022;
  text-decoration: none;
}

/* button style */
.butn {
  background: #073d82;
  color: #ffffff;
  text-align: center;
  padding: 12px 30px;
  line-height: normal;
  font-weight: 500;
  position: relative;
  z-index: 9999;
  display: inline-block;
  white-space: nowrap;
  border: 2px solid #073d82;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: all 0.3s ease-in-out 0s;
}

.butn span {
  z-index: 9;
  position: relative;
}

.butn:hover, .butn:active, .butn:focus {
  color: #073d82;
  background: #f8ea49;
  border: 2px solid #f8ea49;
}

.butn.reverse {
  background: #f8ea49;
  color: #073d82;
  border: 2px solid #f8ea49;
}

.butn.reverse:hover, .butn.reverse:active, .butn.reverse:focus {
  color: #fff;
  background: #073d82;
  border: 2px solid #073d82;
}

.butn.white {
  background: #fff;
  color: #1e2022;
  border: 2px solid #eee;
}

.butn.white:hover, .butn.white:active, .butn.white:focus {
  color: #fff;
  border: none;
  background: #073d82;
  border: 2px solid #073d82;
}

.butn.white-hover:hover {
  color: #1e2022;
}

.butn.white-hover:after {
  background: #fff;
}

.butn.sm {
  padding: 8px 20px;
}

.butn.md {
  padding: 10px 20px;
  font-size: 14px;
}

.butn.lg {
  padding: 16px 44px;
  font-size: 14px;
  margin: 3px;
}

@media screen and (max-width: 767px) {
  .butn {
    font-size: 14px;
    padding: 10px 18px;
  }

  .butn.sm {
    padding: 8px 20px;
  }

  .butn.md {
    padding: 8px 18px;
  }

  .butn.lg {
    padding: 14px 40px;
  }
}

/* ===================================
    Navigation
====================================== */
/* menu area light */
.navbar-nav li.current>a, .navbar-nav li.active>a {
  color: #f8ea49;
}

.navbar>ul>li.current>a:after {
  border-color: transparent #f8ea49 #f8ea49 transparent;
}

.menu_area-light .navbar-nav li.current>a, .menu_area-light .navbar-nav li.active>a {
  color: #f8ea49;
}

.menu_area-light .navbar ul ul li.active>a, .menu_area-light .navbar-nav li.has-sub a:hover {
  color: #f8ea49;
}

.menu_area-light .navbar>ul>li.has-sub>a:hover:after, .menu_area-light .navbar>ul>li.current>a:after {
  border-color: transparent #f8ea49 #f8ea49 transparent;
}

.menu_area-light .navbar ul ul.sub-menu li>a:hover, .menu_area-light .navbar ul ul.sub-menu li.active>a {
  color: #073d82;
}

.menu_area-light.scrollHeader .navbar-nav li.current>a {
  color: #073d82;
}

.menu_area-light.scrollHeader .navbar-nav li.current>a:hover {
  color: #073d82;
}

.menu_area-light.scrollHeader .navbar-nav li.active>a {
  color: #073d82;
}

.menu_area-light.scrollHeader .navbar>ul>li.current>a:after {
  border-color: transparent #073d82 #073d82 transparent;
}

@media screen and (min-width: 992px) {
  .header-style1 .navbar-nav li.current>a, .menu_area-light .navbar-nav li.current>a {
    color: #f8ea49;
  }

  .header-style1 .navbar-nav .sub-menu li.current>a, .menu_area-light .navbar-nav .sub-menu li.current>a {
    color: #073d82;
  }

  .header-style1 .navbar>ul>li.current>a:after, .menu_area-light .navbar>ul>li.current>a:after {
    border-color: transparent #f8ea49 #f8ea49 transparent;
  }

  .header-style1.scrollHeader .navbar-nav li.current>a, .menu_area-light.scrollHeader .navbar-nav li.current>a, .header-style1.scrollHeader .navbar-nav li.current>a:hover, .menu_area-light.scrollHeader .navbar-nav li.current>a:hover {
    color: #073d82;
  }

  .header-style1.scrollHeader .navbar>ul>li.current>a:after, .menu_area-light.scrollHeader .navbar>ul>li.current>a:after {
    border-color: transparent #073d82 #073d82 transparent;
  }
}

@media screen and (max-width: 991px) {
  .menu_area-light .navbar-nav li.current>a, .menu_area-light .navbar-nav li.active>a {
    color: #073d82;
  }
}

/* top search */
.top-search input.form-control::placeholder {
  color: #073d82;
  opacity: 1;
}

.top-search input.form-control::-ms-input-placeholder, .top-search input.form-control::-moz-input-placeholder {
  color: #073d82;
}

.top-search .input-group-addon {
  color: #073d82;
}

.top-search input.form-control {
  color: #073d82;
  font-weight: 500;
}

/* ===================================
   Section heading
====================================== */
.section-heading {
  margin-bottom: 50px;
  text-align: center;
  position: relative;
}

.section-heading h2:before {
  content: '';
  height: 2px;
  background: #f8ea49;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 250px;
}

.section-heading h2:after {
  content: '';
  display: block;
  width: 100px;
  height: 2px;
  background: #073d82;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.section-heading h1, .section-heading h2, .section-heading h3, .section-heading h4, .section-heading h5, .section-heading h6 {
  position: relative;
  margin-bottom: 0;
  font-weight: 600;
  position: relative;
  padding-bottom: 15px;
  color: #073d82;
}

.section-heading.left {
  text-align: left;
}

.section-heading.left h2:before {
  bottom: 0;
  left: 0;
  right: inherit;
  margin: 0 auto;
  width: 250px;
}

.section-heading.left h2:after {
  content: '';
  display: block;
  width: 100px;
  height: 2px;
  background: #073d82;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: inherit;
  bottom: 0;
}

.section-heading.white h2 {
  color: #fff;
}

.section-heading.white h2:after {
  background: #fff;
}

/* ===================================
    Page title
====================================== */
.page-title-section {
  padding: 220px 0 150px;
  text-align: center;
}

.page-title-section h1 {
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1;
  color: #fff;
  margin-bottom: 0;
}

.page-title-section ul {
  margin-bottom: 0;
  margin-top: 15px;
  text-align: center;
}

.page-title-section ul li {
  display: inline-block;
}

.page-title-section ul li:last-child a {
  color: #f8ea49;
}

.page-title-section ul li:last-child:after {
  content: none;
}

.page-title-section ul li:after {
  content: '\f105';
  font-weight: 700;
  vertical-align: middle;
  color: #fff;
  font-family: Font Awesome\ 5 Free;
  padding: 0 5px 0 10px;
}

.page-title-section ul li a {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.page-title-section .active a, .page-title-section li.active:last-child a {
  color: #fff;
}

@media screen and (max-width: 1199px) {
  .page-title-section {
    padding: 180px 0 100px;
  }

  .page-title-section h1 {
    font-size: 42px;
    line-height: 46px;
  }
}

@media screen and (max-width: 991px) {
  .page-title-section {
    padding: 160px 0 100px;
  }

  .page-title-section h1 {
    margin-bottom: 5px;
    font-size: 34px;
    line-height: 40px;
  }

  .page-title-section ul {
    margin-top: 5px;
  }
}

/* ===================================
    Portfolio gallery
====================================== */
.masonry {
  margin: 0;
  padding: 15px;
  -moz-column-gap: 10px;
  -webkit-column-gap: 10px;
  column-gap: 10px;
  transition-duration: .4s;
  -moz-transition-duration: .4s;
  -ms-transition-duration: .4s;
  -webkit-transition-duration: .4s;
  -o-transition-duration: .4s;
}

.masonry a {
  display: block;
  margin-top: 10px;
}

.masonry a:first-child {
  margin-top: 0;
}

.masonry-item {
  position: relative;
  overflow: hidden;
  -moz-transition-duration: .4s;
  -ms-transition-duration: .4s;
  -webkit-transition-duration: .4s;
  -o-transition-duration: .4s;
  transition-duration: .4s;
}

.masonry-item img {
  display: block;
  width: 100%;
  height: auto;
  -moz-transition-duration: .4s;
  -ms-transition-duration: .4s;
  -webkit-transition-duration: .4s;
  -o-transition-duration: .4s;
  transition-duration: .4s;
}

.masonry-item-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  max-height: 100%;
  width: 100%;
  opacity: 0;
  background: rgba(7, 61, 130, 0.8);
  border: 8px solid rgba(255, 255, 255, 0.65);
  padding: 3%;
  -moz-transition-duration: .4s;
  -ms-transition-duration: .4s;
  -webkit-transition-duration: .4s;
  -o-transition-duration: .4s;
  transition-duration: .4s;
}

.masonry-item:hover .masonry-item-overlay {
  position: absolute;
  opacity: 1;
  -moz-transition-duration: .5s;
  -ms-transition-duration: .5s;
  -webkit-transition-duration: .5s;
  -o-transition-duration: .5s;
  transition-duration: .5s;
}

.masonry-item:hover .masonry-item-overlay h4 {
  opacity: 1;
  transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  text-align: center;
  -moz-transition-duration: .6s;
  -ms-transition-duration: .6s;
  -webkit-transition-duration: .6s;
  -o-transition-duration: .6s;
  transition-duration: .6s;
}

.masonry-item:hover .masonry-item-overlay ul {
  opacity: 1;
  transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  text-align: center;
  -moz-transition-duration: .5s;
  -ms-transition-duration: .5s;
  -webkit-transition-duration: .5s;
  -o-transition-duration: .5s;
  transition-duration: .5s;
}

.masonry-item-overlay h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  position: absolute;
  bottom: 40px;
  margin: 0;
  line-height: 20px;
}

.masonry-item-overlay ul {
  position: absolute;
  padding-left: 0;
  bottom: 25px;
  margin: 0;
}

.masonry-item-overlay ul li {
  list-style-type: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  padding-left: 0;
  opacity: .7;
}

.masonry-item-overlay ul li:last-child:after {
  content: none;
}

.masonry-item-overlay ul li:after {
  content: ' / ';
  font-size: 8px;
  font-weight: 700;
  margin: 0 5px;
}

.masonry-item-overlay ul li a {
  color: #fff;
}

.masonry-item:first-child {
  margin-top: 0;
}

@media screen and (min-width: 576px) {
  .masonry {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media screen and (min-width: 768px) {
  .masonry {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media screen and (min-width: 992px) {
  .masonry {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

@media screen and (min-width: 1200px) {
  .masonry {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

/* ===================================
    Service section
====================================== */
.service-wrapper-left, .service-wrapper-right {
  display: flex;
  flex: 0 1 100%;
}

.service-wrapper-left .service-img {
  flex: 0 0 auto;
  max-width: 100%;
  margin-left: 15px;
}

.service-wrapper-right .service-img {
  flex: 0 0 auto;
  max-width: 100%;
  margin-right: 15px;
}

.service-content {
  flex: 0 1 auto;
}

@media screen and (max-width: 991px) {
  .bg-md-hidden {
    background-image: none !important;
  }

  .service-wrapper-left .service-img {
    text-align: center;
    margin-bottom: 20px;
    margin-left: 0;
  }

  .service-wrapper-right .service-img {
    text-align: center;
    margin-bottom: 20px;
    margin-right: 0;
  }

  .service-content {
    text-align: center;
  }

  .border-md-none {
    border: none !important;
  }
}

/* ===================================
    Our Team
====================================== */
.team-wrapper {
  overflow: hidden;
  background: #ffffff;
  height: 100%;
  box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.15);
}

.team-wrapper .team-img {
  position: relative;
}

.team-wrapper:hover .social-icons {
  opacity: 1;
}

.team-wrapper .social-icons {
  position: absolute;
  padding: 0;
  list-style: none;
  width: 100px;
  height: 100px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  transition: ease all 0.55s;
  opacity: 0;
}

.team-wrapper:hover .social-icons:before {
  transform: scale(1);
}

.team-wrapper .social-icons:before {
  height: 100%;
  width: 1px;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: #ffffff;
  right: 0;
  margin: auto;
  transition: ease all 0.55s;
  transform: scale(0);
}

.team-wrapper .social-icons li {
  float: left;
  margin: 5px;
}

.team-wrapper .social-icons a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: block;
  color: #ffffff;
}

.team-wrapper:hover .social-icons:after {
  transform: scale(1);
}

.team-wrapper .social-icons:after {
  width: 100%;
  height: 1px;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: #ffffff;
  right: 0;
  margin: auto;
  transition: ease all 0.55s;
  transform: scale(0);
}

.team-wrapper:hover .team-img:after {
  opacity: 0.8;
}

.team-wrapper .team-img:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: rgba(7, 61, 130, 0.8);
  opacity: 0;
  right: 0;
  transition: ease all 0.55s;
}

/* ===================================
    Testimonial
====================================== */
.testimonial-carousel .testimonial-img {
  display: block;
  margin-right: 20px;
  max-width: 75px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .testimonial-carousel .testimonial-img {
    max-width: 70px;
  }
}

/* ===================================
    Pagination
====================================== */
.pagination {
  border-radius: 0;
  padding: 0;
  margin: 0;
}

.pagination ul {
  display: inline-block;
  margin: 0 auto;
  padding: 0;
}

.pagination li {
  display: inline;
}

.pagination a {
  float: left;
  font-size: 15px;
  padding: 0 18px;
  line-height: 40px;
  text-decoration: none;
  border: 1px solid #dbdbdb;
  border-left-width: 0;
  background: #fff;
}

.pagination a:hover {
  background-color: #1e2022;
  color: #fff;
}

.pagination .active a {
  background-color: #073d82;
  color: #999;
  cursor: default;
}

.pagination li:first-child a {
  border-left-width: 1px;
}

.pagination a:hover {
  background-color: #073d82;
  color: #fff;
}

.pagination .active a {
  background-color: #f7f7f7;
  color: #999;
  cursor: default;
}

@media screen and (max-width: 575px) {
  .pagination a {
    font-size: 14px;
    padding: 0 14px;
  }
}

/*==============================
 *    OwlCarousel
 *    ================================ */
.owl-theme .owl-nav.disabled {
  margin-top: 40px !important;
}

.owl-theme .owl-dots {
  margin-top: 40px !important;
}

.owl-theme .owl-dots .owl-dot span {
  background: rgba(0, 0, 0, 0.15);
}

.owl-theme .owl-dots .owl-dot:hover span, .owl-theme .owl-dots .owl-dot.active span {
  background: #073d82;
}

.owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}

.dots-white.owl-theme .owl-dots .owl-dot span {
  background: rgba(255, 255, 255, 0.5);
}

.dots-white.owl-theme .owl-dots .owl-dot:hover span, .dots-white.owl-theme .owl-dots .owl-dot.active span {
  background: #fff;
}

/* slider-fade1 */
.slider-fade1 .owl-item {
  height: 100vh;
  position: relative;
}

.slider-fade1 .text-center h1 {
  margin-left: auto;
  margin-right: auto;
}

.slider-fade1 .text-right h1 {
  margin-right: 0;
  margin-left: auto;
}

.slider-fade1 .item {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;
}

.slider-fade1 .item .caption {
  width: 100%;
  z-index: 9;
}

.slider-fade1 .owl-carousel .caption .overflow-hidden {
  display: inline-block;
}

.slider-fade1 .owl-carousel .caption h3 {
  font-weight: 500;
  animation-delay: 0.5s;
  display: inline-block;
  position: relative;
  color: #073d82;
}

.slider-fade1 .owl-carousel .caption h1 {
  animation-delay: 0.8s;
  font-size: 4rem;
}

.slider-fade1 .owl-carousel .caption p {
  color: #eee;
  word-spacing: 2px;
  animation-delay: 1.2s;
  margin-bottom: 30px;
}

.slider-fade1 .owl-carousel .caption span {
  display: inline-block;
}

.slider-fade1 .owl-carousel .caption .butn {
  animation-delay: 1.4s;
}

.slider-fade1 .owl-theme .owl-dots {
  position: absolute;
  bottom: 4vh;
  width: 100%;
  left: 0;
}

.slider-fade1 .owl-theme .owl-dots .owl-dot span {
  background: #ffffff;
}

.slider-fade1 .owl-theme .owl-dots .owl-dot:hover span, .slider-fade1 .owl-theme .owl-dots .owl-dot.active span {
  background: #f8ea49;
}

.slider-fade1 .owl-theme .owl-nav {
  margin-top: 0;
}

.slider-fade1 .owl-theme .owl-nav [class*='owl-'] {
  position: absolute;
  right: inherit;
  top: 0;
  bottom: 0;
  width: 60px;
  height: 60px;
  border: none;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 60px;
  line-height: 65px;
  transition-duration: 500ms;
  left: 5vh;
  text-align: center;
  margin: auto;
  opacity: 0.3;
}

.slider-fade1 .owl-theme .owl-nav .owl-next {
  top: 0;
  bottom: 0;
  left: inherit;
  right: 5vh;
}

.slider-fade1 .owl-theme .owl-nav .owl-next:hover {
  opacity: 1;
  background: #073d82;
}

.slider-fade1 .owl-theme .owl-nav .owl-prev:hover {
  opacity: 1;
  background: #073d82;
}

.slider-fade1 .owl-nav i, .slider-fade1 .owl-nav span {
  font-weight: 600;
  color: #fff;
  transition-duration: 500ms;
  font-size: 20px;
}

.slider-fade1 .owl-nav .owl-next:hover i, .slider-fade1 .owl-nav .owl-prev:hover i {
  color: #fff;
}

@media screen and (max-width: 1199px) {
  .slider-fade1 .owl-carousel .caption h1 {
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 991px) {
  .slider-fade1 .owl-carousel .caption h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) {
  .slider-fade1 .owl-carousel .caption h1 {
    font-size: 2.6rem;
  }

  .slider-fade1 .owl-carousel .caption h3 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 575px) {
  .slider-fade1 .owl-carousel .caption h1 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 479px) {
  .slider-fade1 .owl-carousel .caption h1 {
    font-size: 1.8rem;
  }
}

/* ===================================
    Accordion style
====================================== */
.accordion-style1 .card {
  background: transparent;
  box-shadow: none;
  margin-top: 0 !important;
  border: none;
}

.accordion-style1 .card:last-child {
  margin-bottom: 0;
}

.accordion-style1 .card-header {
  border: 0px;
  padding: 0;
  background: none;
}

.accordion-style1 .btn-link {
  border-bottom: none;
  color: #1e2022;
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  white-space: normal;
  padding: 25px 46px 25px 0;
  text-decoration: none;
}

.accordion-style1 .btn-link:hover {
  text-decoration: none;
}

.accordion-style1 .btn-link.collapsed {
  box-shadow: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion-style1 .btn-link.collapsed:after {
  background: none;
  border: 1px solid #073d82;
  border-radius: 50%;
  content: "+";
  right: 0;
  left: inherit;
  font-size: 18px;
  line-height: 24px;
  height: 25px;
  transform: none;
  width: 25px;
  top: 26px;
  text-align: center;
  letter-spacing: -1px;
}

.accordion-style1 .btn-link:after {
  background: none;
  border: 1px solid #073d82;
  border-radius: 50%;
  content: "-";
  right: 0;
  left: inherit;
  font-size: 18px;
  height: 25px;
  line-height: 24px;
  transform: none;
  width: 25px;
  top: 26px;
  position: absolute;
  color: #073d82;
  text-align: center;
  letter-spacing: -2px;
}

.accordion-style1 .btn {
  border-radius: 0;
}

.accordion-style1 .card-body {
  padding: 20px 0 0 0;
  border-top: 1px solid #ededed;
}

/* ===================================
    Tabs
====================================== */
ul.resp-tabs-list {
  margin: 0px;
  padding: 0px;
}

h2.resp-accordion {
  display: none;
}

.resp-tabs-list li {
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  padding: 8px 15px;
  margin: 0 4px 0 0;
  list-style: none;
  cursor: pointer;
  min-width: 180px;
}

.resp-tabs-list li:last-child {
  margin-right: 0;
}

.resp-tabs-container {
  padding: 0px;
  background-color: #fff;
  clear: left;
}

.resp-tab-content {
  display: none;
  padding: 40px 0 40px 0;
}

.resp-tabs-list li.resp-tab-active {
  background: #073d82;
  background: linear-gradient(-45deg, #073d82, #8089ff);
  padding: 15px 20px 13px 20px;
  color: #073d82;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.resp-content-active, .resp-accordion-active {
  display: block;
}

.resp-tab-content {
  border: 1px solid #e4e4e4;
  border-top-color: #e4e4e4;
  float: left;
  width: 100%;
}

h2.resp-tab-active span.resp-arrow {
  border-color: #fff transparent transparent #fff;
  border-style: solid;
  border-width: 1px 0 0 1px;
  float: right;
  display: block;
  height: 8px;
  transform: rotate(45deg);
  width: 8px;
  margin-top: 7px;
}

/* tab-style1 */
.tab-style1 ul.resp-tabs-list {
  display: flex;
  justify-content: space-between;
  border-bottom: 4px solid rgba(0, 0, 0, 0.08);
}

.tab-style1 .resp-tab-content {
  border: none;
  border-top-color: #e4e4e4;
  padding: 30px 0 0 0;
}

.tab-style1 .resp-tabs-list li {
  position: relative;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  padding: 0 0 10px 0;
  margin: 0;
  list-style: none;
  cursor: pointer;
  min-width: auto;
  color: #9b9b9b;
  background: none !important;
}

.tab-style1 .resp-tabs-list li:last-child {
  margin-right: 0;
}

.tab-style1 .resp-tabs-list li.resp-tab-active {
  border: 4px solid #073d82;
  border-bottom: none;
  border-color: #073d82 !important;
  margin-bottom: 0;
  border-top: 4px solid #073d82 !important;
  border-bottom: 0px #fff solid;
  border-bottom: none;
  background-color: #fff;
  color: #073d82;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.tab-style1 .resp-tabs-list li.resp-tab-active:after {
  content: "";
  background: #073d82;
  height: 4px;
  width: 100%;
  position: absolute;
  bottom: -4px;
  left: 0;
  margin: 0 auto;
  right: 0;
}

@media screen and (max-width: 767px) {
  .tab-style1 .resp-tab-content {
    padding: 25px 0 0 0;
  }
}

/* ===================================
    Pages
====================================== */
/* about us */
.about-list {
  list-style: none;
  margin-bottom: 0;
}

.about-list li {
  line-height: 24px;
  margin-bottom: 10px;
  position: relative;
  padding-left: 30px;
}

.about-list li:after {
  content: '\f00c';
  font-family: Font Awesome\ 5 Free;
  position: absolute;
  left: 0px;
  top: 1px;
  z-index: 1;
  font-weight: 600;
  color: #073d82;
}

@media screen and (max-width: 575px) {
  .about-list li {
    font-size: 14.4px;
    padding-left: 25px;
  }
}

/* what we offer page */
.icon-count-number {
  position: absolute;
  right: 20px;
  top: 5px;
  font-size: 80px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.06);
  line-height: 80px;
}

@media screen and (max-width: 1199px) {
  .icon-count-number {
    font-size: 75px;
  }
}

@media screen and (max-width: 991px) {
  .icon-count-number {
    font-size: 70px;
  }
}

@media screen and (max-width: 767px) {
  .icon-count-number {
    font-size: 65px;
  }
}

@media screen and (max-width: 575px) {
  .icon-count-number {
    font-size: 60px;
  }
}

/*pricing page*/
.price .price-title {
  position: relative;
  padding-bottom: 15px;
  border-bottom: 1px solid #ededed;
}

.price .price-title:after {
  content: '';
  width: 70px;
  height: 1px;
  background: #073d82;
  position: absolute;
  bottom: -1px;
  left: calc(50% - 35px);
}

.price .pricing-list {
  list-style: none;
}

.price .pricing-list li {
  color: #1e2022;
  font-weight: 500;
  font-size: 16px;
  padding: 0 40px 20px 40px;
  margin-bottom: 20px;
  border-bottom: 2px dashed #ededed;
}

@media screen and (max-width: 575px) {
  .price .pricing-list li {
    font-size: 14.4px;
  }
}

/* contact page */
.map {
  height: 400px;
  width: 100%;
  vertical-align: top;
  border: 0;
}

.contact-box {
  padding: 40px 20px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 1199px) {
  .contact-box {
    padding: 30px 20px;
  }
}

@media screen and (max-width: 767px) {
  .contact-box {
    padding: 25px;
  }
}

/* coming soon page */
.countdown {
  margin-left: -5px;
}

.countdown li {
  background: #fff;
  display: inline-block;
  text-align: center;
  min-width: 130px;
  padding: 15px 10px;
  border-radius: 0.25rem;
  margin-left: 5px;
}

.countdown li span {
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  color: #073d82;
  line-height: normal;
  position: relative;
}

.countdown li span:before {
  content: "";
  height: 1px;
  position: absolute;
  width: 100%;
}

.countdown li p.timeRefDays, .countdown li p.timeRefHours, .countdown li p.timeRefMinutes, .countdown li p.timeRefSeconds {
  font-size: 16px;
  font-weight: 500;
  color: #073d82;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}

@media screen and (max-width: 991px) {
  .countdown li span {
    font-size: 32px;
  }
}

@media screen and (max-width: 767px) {
  .countdown {
    margin-top: -10px;
  }

  .countdown li {
    min-width: 180px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 575px) {
  .countdown li {
    min-width: 47%;
  }
}

/* countdown social */
.countdown-social {
  margin-bottom: 0;
}

.countdown-social li {
  text-align: center;
  margin-right: 5px;
  display: inline-block;
}

.countdown-social li:last-child {
  margin-right: 0;
}

.countdown-social li a {
  color: #ffffff;
  border-radius: 4px;
  height: 35px;
  width: 35px;
  line-height: 35px;
  display: inline-block;
  font-size: 14px;
  border: 1px solid #ffffff;
}

.countdown-social li a:hover {
  background: #f8ea49;
  color: #073d82;
  border: 1px solid #f8ea49;
}

/*404 page*/
.error-wrapper h1 {
  font-size: 250px;
  text-align: center;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 1px;
  color: #ffffff;
  margin-bottom: 20px;
}

@media screen and (max-width: 1199px) {
  .error-wrapper h1 {
    font-size: 200px;
  }
}

@media screen and (max-width: 991px) {
  .error-wrapper h1 {
    font-size: 180px;
  }
}

@media screen and (max-width: 767px) {
  .error-wrapper h1 {
    font-size: 160px;
  }
}

@media screen and (max-width: 575px) {
  .error-wrapper h1 {
    font-size: 140px;
  }
}

/* ===================================
    Blog
====================================== */
.blog-wrapper {
  padding: 25px;
  box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.15);
  height: 100%;
}

/*blog list page*/
.post-list {
  margin-bottom: 1.5rem;
}

.post-list li {
  display: inline-block;
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  font-weight: 600;
}

.post-list li:last-child {
  border-right: 0;
}

/*sidebar*/
.side-bar .widget {
  margin-bottom: 30px;
}

.side-bar .widget:last-child {
  margin-bottom: 0;
}

.widget-title {
  border-bottom: 1px solid #ededed;
  color: #1e2022;
  display: block;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 30px;
  padding-bottom: 10px;
  position: relative;
}

.widget-title:before {
  background: #073d82;
  bottom: -1px;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  width: 60px;
}

.side-bar .search form input {
  width: calc(100% - 50px);
  height: 50px;
  padding: 0 10px;
  margin: 0;
  border: 1px solid #ededed;
  background: #f7f7f7;
}

.side-bar .search form button {
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  border: 0;
  float: right;
  border-radius: 0;
  padding: 0;
  box-shadow: none;
  margin: 0;
}

.side-bar .search form button:hover:before {
  border-radius: 0;
  padding: 0;
}

.cat-list {
  margin: -15px 0 0 0;
  padding: 0;
}

.cat-list ul {
  margin-bottom: 0;
}

.cat-list li {
  font-size: 15px;
  color: #1e2022;
  text-align: left;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  position: relative;
}

.cat-list li a {
  color: #1e2022;
  text-transform: capitalize;
  border-bottom: 1px dotted #dddddd;
  width: 100%;
  font-weight: 500;
  padding-left: 0;
  display: inline-block;
}

.cat-list li a i {
  color: #1e2022;
  font-size: 15px;
  display: inline-block;
  margin: 0 10px 0 0;
}

.side-bar .widget .social-listing {
  margin-bottom: 0;
}

.side-bar .widget .social-listing li {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
}

.side-bar .widget .social-listing li:last-child {
  margin-right: 0;
}

.side-bar .widget .social-listing li a {
  color: #1e2022;
}

.side-bar .widget .social-listing li a:hover {
  color: #073d82;
}

/* blog tags*/
.blog-post-tag {
  margin-bottom: 0;
}

.blog-post-tag li {
  display: inline-block;
  list-style: none;
}

.blog-post-tag li a {
  color: #2b303b;
  font-size: 14px;
  display: inline-block;
  font-weight: 600;
  text-transform: capitalize;
  padding: 3px 8px;
  margin: 4px 4px 4px 0;
  border: 1px dashed #ededed;
  line-height: 30px;
}

.blog-post-tag li a:hover {
  background: #073d82;
  color: #ffffff;
  border: 1px solid #073d82;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .widget-title {
    font-size: 18px;
    margin-bottom: 25px;
  }
}

/*blog detail*/
.blogs .posts .content {
  padding: 30px;
  border-top: none;
  margin-bottom: 50px;
  box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.15);
}

.blogs .posts .content .meta {
  margin-bottom: 16px;
}

.blogs .posts .content .meta li {
  display: inline-block;
  font-size: 14px;
  color: #1e2022;
  margin: 5px 5px 0 5px;
}

.blogs .posts .content .special {
  padding: 20px;
  margin: 30px 0;
  border-left: 3px solid #073d82;
  background: #f7f7f7;
  font-size: 18px;
  color: #073d82;
}

.blogs .posts .content .separator {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px dashed #ddd;
  text-align: left;
}

.blogs .posts .content .share-post {
  text-align: right;
}

.blogs .posts .content .share-post ul li {
  display: inline-block;
  margin-left: 15px;
}

.blogs .posts .content .tags .blog-post-tag {
  margin-bottom: 0;
}

.blogs .posts .content .tags .blog-post-tag li {
  display: inline-block;
  color: #7A7A7A;
  font-size: 14px;
  text-transform: capitalize;
  padding: 12px 16px;
  margin-right: 6px;
  border-radius: 4px;
  border: 1px solid #ededed;
  line-height: 10px;
}

.blogs .posts .comments-area {
  padding: 30px;
  box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.15);
}

.blogs .posts .comments-area .comment-box {
  padding-bottom: 30px;
  margin-bottom: 50px;
  border-bottom: 1px solid #eee;
}

.blogs .posts .comments-area .comment-box:nth-child(odd) {
  margin-left: 80px;
}

.blogs .posts .comments-area .comment-box:last-child {
  margin-bottom: 30px;
}

.blogs .posts .comment-box .author-thumb {
  width: 80px;
  float: left;
}

@media screen and (max-width: 991px) {
  .blogs .posts .content .share-post ul li {
    font-size: 15px;
  }

  .blogs .posts .comments-area .comment-box {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  .blogs .posts .content {
    padding: 25px;
  }

  .blogs .posts .content .separator {
    margin-top: 25px;
    padding-top: 25px;
  }

  .blogs .posts .content .share-post {
    text-align: unset;
  }

  .blogs .posts .comment-box .author-thumb {
    width: 75px;
  }

  .blogs .posts .comments-area {
    padding: 25px;
  }

  .blogs .posts .comments-area .comment-box:nth-child(odd) {
    margin-left: 0;
  }
}

@media screen and (max-width: 575px) {
  .blogs .posts .comment-box .author-thumb {
    width: 70px;
  }
}

/* ===================================
    Others
====================================== */
/*history section*/
.history-carousel .owl-dots .owl-dot span {
  background: #ffffff;
}

.history-carousel .owl-dots .owl-dot:hover span, .history-carousel .owl-dots .owl-dot.active span {
  background: #f8ea49;
}

.history-carousel .history-wrapper {
  padding: 60px 40px 90px;
  position: relative;
  z-index: 1;
}

.history-carousel .history-wrapper::before {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0) 100%);
  opacity: .68;
  pointer-events: none;
  z-index: -1;
}

/* destination block */
.destination-block .destination-img {
  overflow: hidden;
  padding: 0 18px;
}

.destination-block .destination-content {
  padding: 130px 40px 30px 40px;
  margin-top: -100px;
  box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.15);
  text-align: center;
}

@media screen and (max-width: 1199px) {
  .destination-block .destination-content {
    padding: 130px 30px 30px 30px;
  }
}

/* ===================================
    Footer
====================================== */
.form-control:focus {
  border-color: #073d82;
}

.quform-input {
  position: relative;
}

.quform-input .quform-errors-wrap {
  position: absolute;
  right: 8px;
  top: 0;
  line-height: normal;
  z-index: 1;
}

.quform-element>label {
  font-weight: normal;
  padding-bottom: 5px;
  margin-bottom: 0;
  color: #6a747b;
  font-size: 14px;
}

.quform-element>label .quform-required {
  color: #cc0101;
  font-size: 10px;
}

.quform-inner input {
  width: 100%;
}

.quform-elements .quform-element textarea {
  margin-bottom: 0;
  padding: 8px 15px;
  vertical-align: top;
}

.quform-elements .quform-element select {
  margin-bottom: 0;
  padding: 8px 15px;
}

.quform-errors {
  padding: 0;
  margin: 0;
  line-height: normal;
}

.quform-errors>.quform-error {
  padding: 0;
  background: none;
  border: none;
  float: none;
  color: #f5543f;
  font-size: 11px;
  line-height: normal;
  letter-spacing: normal;
}

.quform-outer-no-js .quform-error {
  padding: 0;
  background: none;
  border: none;
  float: none;
  color: #f5543f;
  font-size: 11px;
  line-height: normal;
  letter-spacing: normal;
}

.quform-outer-no-js .quform-success-message {
  padding: 0.75rem 1.25rem 0.75rem 3rem;
}

.quform-has-error input, .quform-has-error textarea, .quform-has-error select, .quform-has-error input[type=file], .quform-has-error .custom-file-label {
  border-color: #f5543f;
}

.quform-success-message {
  padding: 0.75rem 1.25rem 0.75rem 3rem;
}

.quform-submit-inner {
  float: none;
}

.quform-loading-wrap {
  float: none;
}

.quform-loading-wrap .quform-loading {
  display: inline-block;
}

.light-validation .quform-errors>.quform-error {
  color: #fff;
}

/* newsletter */
.newsletter-form .quform-elements {
  position: relative;
}

.newsletter-form .quform-submit-inner {
  position: absolute;
  right: 16px;
  top: -49px;
  width: auto;
  background: #f5f5f5;
  height: 48px;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  -webkit-border-top-right-radius: 0.25em;
  -webkit-border-bottom-right-radius: 0.25em;
  -moz-border-radius-topright: 0.25em;
  -moz-border-radius-bottomright: 0.25em;
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}

.newsletter-form .quform-loading-wrap {
  margin-top: 15px;
  margin-bottom: 0;
  margin-left: 0;
}

.newsletter-form input {
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 50px;
  padding: 0.5rem 4rem 0.5rem 1rem;
}

.newsletter-form .quform-has-error input, .newsletter-form .quform-has-error textarea, .newsletter-form .quform-has-error select {
  border-color: #f5543f;
}

.newsletter-form .quform-input .quform-errors-wrap {
  right: 15px;
}

.newsletter-form i {
  font-size: 1.2rem;
  line-height: 2rem;
}

footer {
  background: #1e2022;
}

/*footer bar*/
.footer-bar {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  position: relative;
  z-index: 9;
}

/*footer title*/
.footer-title {
  border-bottom: 1px solid #f8ea49;
  display: block;
  margin-bottom: 30px;
  padding-bottom: 15px;
  position: relative;
  color: #fff;
}

.footer-title:before {
  background: #fff;
  bottom: -2px;
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  width: 40px;
}

/*footer list*/
.address-list {
  margin-bottom: 25px;
  list-style: none;
}

.address-list li {
  color: #fff;
  padding: 6px 0;
  font-size: 15px;
}

.address-list li a {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
}

@media screen and (max-width: 575px) {
  .address-list {
    margin-bottom: 20px;
  }
}

/*footer-social-icon*/
.footer-social-icon {
  margin-bottom: 0;
}

.footer-social-icon li {
  text-align: center;
  margin-right: 5px;
  display: inline-block;
}

.footer-social-icon li a {
  border: 1px solid #fff;
  color: #fff;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  line-height: 35px;
  display: inline-block;
  font-size: 15px;
}

.footer-social-icon li a:hover {
  background: #f8ea49;
  color: #073d82;
  border: 1px solid #f8ea49;
}

@media screen and (max-width: 767px) {
  .footer-social-icon li a {
    height: 32px;
    width: 32px;
    line-height: 32px;
    font-size: 13px;
  }
}

/*footer-list*/
.footer-list {
  margin: 0;
}

.footer-list li {
  list-style-type: none;
  text-indent: -34px;
  padding-left: 34px;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 7px;
}

.footer-list li:last-child {
  margin-bottom: 0;
}

.footer-list li a {
  color: #fff;
  vertical-align: middle;
}

.footer-list li a:before {
  width: 15px;
  margin-right: 12px;
  background: #fff;
  display: inline-block;
  vertical-align: middle;
  content: '';
  height: 2px;
  transition: inherit;
}

.footer-list li a:hover {
  color: #f8ea49;
}

.footer-list li a:hover:before {
  background: #f8ea49;
}

/*# sourceMappingURL=styles.css.map */